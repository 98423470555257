<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-8">
          <div class="card" style=" overflow-y:scroll;height:600px">
              <div class="card-header">
                  <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle bg-light">
                          <img class="mx-auto d-block" src="@/assets/images/login/logo.png" height="80" />
                      </span>
                  </div>
              </div>
              <div class="card-body">
                  <b-overlay :show="loading">
                    <div class="p-2">
                      <b-alert :show="errorAlert" variant=" " dismissible fade class="text-white bg-danger">
                        <div class="iq-alert-icon">
                          <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">{{ errorMsg }}</div>
                      </b-alert>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(signup)" @reset.prevent="reset" autocomplete="off">
                        <b-row>
                            <b-col>
                                <ValidationProvider name="Mobile Number" vid="mobile_no" rules="required">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mobile_no"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('Mobile number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="mobile_no"
                                        v-model="user.mobile_no"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                             </b-col>
                            <b-col>
                               <ValidationProvider name="Nid" vid="nid">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="nid"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('NID Number')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="nid"
                                        v-model="user.nid"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <ValidationProvider name="Name" vid="name" rules="required">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('Name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="name"
                                        v-model="user.name"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                             </b-col>
                             <b-col>
                                  <ValidationProvider name="Name (bn)" vid="name_bn">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('Name Bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="name_bn"
                                        v-model="user.name_bn"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                             </b-col>
                           </b-row>
                          <b-row>
                            <b-col>
                              <ValidationProvider name="Service Type" vid="service_type">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="service_type"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('Service Type')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="service_type"
                                        v-model="user.service_type"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                 <ValidationProvider name="Service Type bn" vid="service_type_bn">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Service Type (bn):"
                                        label-for="service_type_bn"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-input
                                            id="service_type_bn"
                                            v-model="user.service_type_bn"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                  </ValidationProvider>
                            </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                 <ValidationProvider name="Division" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="division"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('Division')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="user.division_id"
                                        :options="divisionList"
                                        id="division_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                               </b-col>
                               <b-col>
                                   <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="region"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('Region')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="user.region_id"
                                            :options="regionLists"
                                            id="region_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                      <ValidationProvider name="District" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="district"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('District')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="user.district_id"
                                            :options="districtList"
                                            id="district_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                               <b-col>
                                  <ValidationProvider name="Upazila" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="upazila_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('Upazilla')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="user.upazilla_id"
                                            :options="upazilaList"
                                            id="upazila_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                 </ValidationProvider>
                               </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                    <ValidationProvider name="Union" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="union_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('Union')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="user.union_id"
                                            :options="unionList"
                                            id="union_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                               <b-col>
                                   <ValidationProvider name="Warehouse Type" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="warehouse_type_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                            {{ $t('Warehouse Type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="user.warehouse_type_id"
                                            :options="warehouseTypeList"
                                            id="warehouse_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                   <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="warehouse_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{$t('Warehouse')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="user.warehouse_id"
                                            :options="warehouseNameList"
                                            id="warehouse_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                            </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                               <b-col>
                                  <ValidationProvider name="Address" vid="address" rules="required">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="address"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('Address')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="address"
                                            v-model="user.address"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                    <ValidationProvider name="Address bn" vid="address_bn">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Address bn:"
                                        label-for="address_bn"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-input
                                            id="address_bn"
                                            v-model="user.address_bn"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                               <b-col>
                                  <ValidationProvider name="Father Name" vid="father_name" rules="required">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="father_name"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('Father Name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="father_name"
                                            v-model="user.father_name"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                           </b-row>
                           <b-row>
                               <b-col>
                                  <ValidationProvider name="Father Name Bn" vid="father_name_bn">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Father Name Bn:"
                                        label-for="father_name_bn"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-input
                                            id="father_name_bn"
                                            v-model="user.father_name_bn"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                   </ValidationProvider>
                               </b-col>
                               <b-col>
                                    <ValidationProvider name="Land Type" vid="land_type_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="land_type_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('Land Type')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="user.land_type_id"
                                        :options="landTypeList"
                                        id="land_type_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                               </b-col>
                           </b-row>
                          <b-row>
                              <b-col>
                                   <ValidationProvider name="Land Area" vid="land_area" rules="required">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Land Area:"
                                        label-for="land_area"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('Land Area')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                             type="number"
                                            id="land_area"
                                            v-model="user.land_area"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                              </b-col>
                               <b-col>
                                    <ValidationProvider name="Password" vid="password" rules="required|min:6">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="password"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{ $t('Password')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="password"
                                        type="password"
                                        v-model="user.password"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                                 </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                   <ValidationProvider name="Confirm password" vid="password_confirmation" rules="required">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="password_confirmation"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                         {{ $t('Confirm Password')}}<span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="password_confirmation"
                                            type="password"
                                            v-model="user.password_confirmation"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                     </ValidationProvider>
                              </b-col>
                              <b-col>
                                 <ValidationProvider name="Remarks" vid="remarks">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Remarks:"
                                        label-for="remarks"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-textarea
                                            id="remarks"
                                            v-model="user.remarks"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                   <ValidationProvider name="Remarks Bn" vid="remarks">
                                        <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label="Remarks Bn:"
                                        label-for="remarks_bn"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <b-form-textarea
                                            id="remarks_bn"
                                            v-model="user.remarks_bn"
                                            placeholder=""
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                   </ValidationProvider>
                              </b-col>
                              <b-col>
                              </b-col>
                          </b-row>
                            <div class="mt-3">
                                <button class="btn btn-primary btn-block" type="submit">Sign up</button>
                            </div>
                            <div class="mt-4 text-center">
                              <router-link to="/auth/login">Back to Login</router-link>
                            </div>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </b-overlay>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { warehouseSignUp } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    loading: false,
    defaultValue: '',
    user: {
      name: '',
      name_bn: '',
      email: 'fm_@gmail.com',
      password: '',
      password_confirmation: '',
      user_type_id: '',
      mobile_no: '',
      service_type: '',
      service_type_bn: '',
      division_id: 0,
      region_id: 0,
      district_id: 0,
      upazilla_id: 0,
      union_id: 0,
      warehouse_type_id: 0,
      warehouse_id: 0,
      address: '',
      address_bn: '',
      father_name: '',
      father_name_bn: '',
      land_type_id: 0,
      land_area: '',
      remarks: '',
      remarks_bn: '',
      nid: ''

    },
    districtList: [],
    upazilaList: [],
    unionList: [],
    warehouseNameList: [],
    regionLists: [],
    errorAlert: false,
    errorMsg: '',
    landTypeList: [
        { text: 'Own', value: 1 },
        { text: 'Lease', value: 2 }
    ]
  }),
  created () {
    if (this.id) {
      const tmp = this.getWarehouseInformationData()
      Object.freeze(tmp)
      this.warehouse_info = tmp
    }
  },
  mounted () {
    this.getRegionList()
    this.getWarehouseTypeList()
    this.warehouseNameList()
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    ...mapGetters({
      userTypeList: 'Auth/userTypeList'
    })
  },
  watch: {
    'user.division_id': function (newVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'user.district_id': function (newVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'user.upazilla_id': function (newVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'user.warehouse_type_id': function (newVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, this.user.upazilla_id)
    }
  },
  methods: {
    getWarehouseInformationData () {
      return this.$store.state.WareHouseConfig.warehouseInformations.find(item => item.id === this.id)
    },
    async signup () {
      try {
        this.loading = false
        const result = await RestApi.postData(authServiceBaseUrl, warehouseSignUp, this.user)
        if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Registration saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }

        if (result.success) {
          this.$router.push({ name: 'auth.login' })
        } else {
          this.errorAlert = true
          this.errorMsg = 'Failed to sign up. Please, try again. ttt'
          this.$refs.form.setErrors(result.errors)
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.errorMsg = 'Failed to sign up. Please, try again.'
      }

      this.loading = false
    },
     getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObject = response.filter(document => document.region_id === regionId)
                this.regionDistrictLists = dataObject.map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.district_id, text: this.districtListBn[obj.district_id] }
                        } else {
                            return { value: obj.district_id, text: this.districtList[obj.district_id] }
                        }
                })
            }
        })
    },
    getRegionList () {
      RestApi.getData(warehouseServiceBaseUrl, '/master-region-info/list-all').then(response => {
        if (response.success) {
          this.regionLists = response.data.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.region_name_bn }
            } else {
              return { value: obj.id, text: obj.region_name }
            }
          })
        }
        })
    },
   getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getWarehouseTypeList () {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehousetype/list-all').then(response => {
        if (response.success) {
          this.warehouseTypeList = response.data.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.warehouse_type_name_bn }
            } else {
              return { value: obj.id, text: obj.warehouse_type_name }
            }
          })
        }
      })
    },
     getWarehouseName (typeId, upazillaId) {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-info/list-allOp').then(response => {
        const dataObject = response.data.filter(warehouseinfo => warehouseinfo.warehouse_type_id === typeId && warehouseinfo.upazilla_id === upazillaId)
          this.warehouseNameList = dataObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.warehouse_name_bn }
          } else {
            return { value: obj.id, text: obj.warehouse_name }
          }
        })
      })
    }
  }
}
</script>

<style scoped>
        .card{border: 0px!important;}
        .card-header{background-color: #c7cef8 !important; border-bottom: 0px!important;}
        .btn-custom{background-color: #596DE1; color: #fff;}
</style>
